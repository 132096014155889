<template>
  <div id="vodTv">
    <el-form label-position="right">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :xs="24" :sm="18" :md="16" :lg="14" :xl="12">
          <div>
            <video id="my-video" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" controls
                   preload="auto">
            </video>
          </div>
        </el-col>
      </el-row>
      <el-row  type="flex" class="row-bg" justify="center" style="margin: 20px 0 0 0; text-align: right">
        <el-col :xs="14" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: left">
        </el-col>
        <el-col :xs="10" :sm="8" :md="6" :lg="4" :xl="2" v-if="lineVod.length > 0">
          <div class="demo-input-suffix">
            线路：
            <el-select v-model="vodLink" placeholder="线路选择" @change="changeLink" style="width: 100px">
              <el-option :label="'线路' + index" :value="item" v-for="(item, index) in lineVod" :key="index"></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" v-loading="loading"
                element-loading-text="拼命加载频道中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" style="overflow:auto;border: 1px solid #E4E7ED;">
          <el-cascader-panel :options="options" @change="changeTree" ref="panel" :key="cascaderKey"></el-cascader-panel>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import videojs from "video.js";
import "videojs-contrib-hls";
import index from "@/request/expand/tv/index";

export default {
  name: "vodTv",
  data() {
    return {
      ask: new index(),
      myPlayer: null,
      vodLink: '',
      options: [],
      value: '',
      lineVod: [],
      loading: true,
      cascaderKey: 123450
    };
  },
  methods: {
    getVideo() {
      this.myPlayer = videojs('my-video',
          {
            "poster": "http://file.charon520.vip/tv2.jpg",
            "controls": "true",
            playbackRates: [0.5, 1, 1.5, 2, 3]
          }, function () {
            this.on('play', function () {
              console.log('正在播放');
            });
            //暂停--播放完毕后也会暂停
            this.on('pause', function () {
              console.log("暂停中")
            });
            // 结束
            this.on('ended', function () {
              console.log('结束');
            })
            // 等待数据
            this.on('waiting', function () {
              console.log("等待数据")
            })
          });
    },
    /**
     * 播放
     * @param link
     */
    checkVideo(link) {
      this.myPlayer.src([
        {
          src: link
        }
      ]);
      this.myPlayer.play();
    },
    // 加载所有频道
    loadTreeAll(isShow) {
      this.loading = true
      this.ask.getTreeAll().then(res => {
        if (res.msg != null) {
          this.clearAttribute(res.msg, isShow);
          this.options = res.msg
          this.options.unshift({
            "label": "开启全部",
            "value": "",
            "children": [
              {"label": "开启", "value": "openAll-yes",},{"label": "关闭", "value": "openAll-no",}
            ]
          })
          this.loading = false
        }
      })
    },
    // 选择节点
    changeTree(data) {
      if (data != null || data.length > 0) {
        const link = data[data.length - 1];
        if (link === 'openAll-yes') {
          this.cascaderKey = new Date().getTime()
          this.openTreeAllVerify()
        } else if (link === 'openAll-no') {
          this.cascaderKey = new Date().getTime()
          this.loadTreeAll(false)
        } else if (link != undefined && link !== '') {
          this.vodLink = ''
          if (link.indexOf(',') > 0) {
            this.lineVod = link.split(',')
            this.checkVideo(this.lineVod[0])
            this.vodLink = this.lineVod[0]
          } else {
            this.lineVod = []
            this.checkVideo(link)
          }
        }
      }
    },
    // 清除对象上多余属性
    clearAttribute(list, isShow) {
      for (let i = 0; i < list.length; i++) {
        let obj = list[i]
        if (obj.value === 'disabled' && !isShow) {
          obj.disabled = true
          obj.label = '****'
        } else {
          obj.disabled = false
        }
        if (obj.children == null || obj.children.length === 0) {
          delete obj.children
        } else {
          this.clearAttribute(obj.children, isShow)
        }
      }
    },
    /**
     * 清除节点
     */
    clearCheckedNodes() {
      this.$refs.panel.clearCheckedNodes()
    },
    /**
     * 线路切换
     */
    changeLink (data) {
      this.vodLink = data
      this.checkVideo(data)
    },
    /**
     * 打开全部树结构鉴权
     */
    openTreeAllVerify() {
      this.$prompt('请输入密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (value === '00520') {
          this.loadTreeAll(true)
        } else {
          this.$message.error('密码错误');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  },
  mounted() {
    this.getVideo();
    this.loadTreeAll(false)
  },
  destroyed() {
    //页面销毁，同时也销毁video.js对象
    this.myPlayer.dispose();
  },
  watch: {},
};
</script>

<style scoped>
/deep/ .el-cascader-panel.is-bordered {
  border: none;
}
</style>